import {navigate} from 'gatsby'
import {LANGUAGES_KEYS, COMMON_LANGUAGES, ROUTES, DEFAULT_LANGUAGE} from './src/const'

import {APP_NOT_AVAILABLE} from './src/config'

export const onRouteUpdate = ({location}) => {
  if (APP_NOT_AVAILABLE && !location.pathname.includes(ROUTES.INFO)) {
    navigate(ROUTES.INFO)
  }
}

export const onClientEntry = () => {
  const {pathname} = window.location
  const pathnameRegExp = new RegExp(
    `^(/(${Object.keys(LANGUAGES_KEYS).join('|')}))?(${Object.values(ROUTES).join('|')}|/404/)$`
  )

  if (!pathnameRegExp.test(pathname)) {
    const langRegExp = new RegExp(`^/(${COMMON_LANGUAGES.join('|')})/.*`)
    const lang = langRegExp.test(pathname) ? String(pathname).slice(1, 3) : DEFAULT_LANGUAGE
    window.location.replace(`/${lang}/404/`)
  }
}
